import firebase from "firebase/app";
import canvg from "canvg";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import HCMore from "highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
Exporting(Highcharts);
SolidGauge(Highcharts);
HCMore(Highcharts);

class LVSExtention extends window.Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
    this.tree = null;
    this.light = null;
    this.camera = viewer.impl.camera;
    this.room = null;
    this.gauges = [];
    this.chartDiv = null;
    this.firebaseRoomHandle = null;
    // binding
    this.setupUI = this.setupUI.bind(this);
    this.createGauge = this.createGauge.bind(this);
    this.getObjectTree = this.getObjectTree.bind(this);
    this.onNewData = this.onNewData.bind(this);
    this.updateRoomGaugeTexture = this.updateRoomGaugeTexture.bind(this);
    this.processCamera = this.processCamera.bind(this);
    this.createChartHiddenDiv = this.createChartHiddenDiv.bind(this);
  }

  load() {
    if (this.viewer.isExtensionLoaded("BtibFirebaseExtension")) {
      if (!this.viewer.isExtensionActive()) {
        this.viewer.activateExtension("BtibFirebaseExtension");
      }
    } else {
      return false;
    }

    this.createChartHiddenDiv();
    this.tree = this.viewer.model.getData().instanceTree;
    this.cam = this.viewer.getCamera();
    this.light = new window.THREE.AmbientLight(0x404040); // soft white light
    this.viewer.impl.scene.add(this.light);
    window.addEventListener("newData", this.onNewData, false);
    this.retrieveRoomFromFb();
    this.viewer.addEventListener(window.Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.processCamera);
    this.viewer.disableSelection(true);
    this.viewer.disableHighlight(true);
    return true;
  }

  unload() {
    window.removeEventListener("newData", this.onNewData, false);
    window.removeEventListener(
      window.Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.processCamera,
      false
    );
    if (this.chartDiv) {
      document.body.removeChild(this.chartDiv);
    }
    this.gauges.forEach(gauge => this.viewer.impl.scene.remove(gauge));
    this.viewer.impl.scene.remove(this.light);
    this.room = null;

    if (this.firebaseRoomHandle) {
      this.firebaseRoomHandle.off("value");
    }
    this.gauges = [];
    this.viewer.impl.sceneUpdated(true);
    return true;
  }

  async setupUI() {
    const nodeId = this.findNodeIdbyName("Local technique 11");
    let solid = null;
    this.tree.enumNodeChildren(nodeId, subChild => (solid ? 0 : (solid = subChild)));
    let transMat = this.getFragmentWorldMatrixByNodeId(solid).matrix[0];
    const pos = new window.THREE.Vector3();
    pos.setFromMatrixPosition(transMat);
    // Gauge
    const gaugeChartOptions = this.createGaugeChartOptions(this.room);
    const gaugeCanvas = await this.createCanvasChart(gaugeChartOptions);
    let gaugeMesh = await this.createGauge(gaugeCanvas, 8, 6);
    gaugeMesh.position.set(pos.x, pos.y - 4, pos.z + 9);
    this.viewer.impl.scene.add(gaugeMesh);
    this.gauges = [...this.gauges, gaugeMesh];
    // Pie
    const pieChartOptions = this.createGaugeChartOptions(this.room);
    const pieCanvas = await this.createCanvasChart(pieChartOptions);
    let pieMesh = await this.createGauge(pieCanvas);
    pieMesh.position.set(pos.x, pos.y + 5, pos.z + 9);
    this.viewer.impl.scene.add(pieMesh);
    this.gauges = [...this.gauges, pieMesh];
    // update the scene
    this.viewer.impl.sceneUpdated(true);
  }

  getObjectTree() {
    this.viewer.removeEventListener(
      window.Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
      this.getObjectTree
    );
    this.tree = this.viewer.model.getData().instanceTree;
    this.retrieveRoomFromFb();
  }

  createGaugeChartOptions(room) {
    return {
      chart: {
        type: "solidgauge",
        plotBackgroundColor: "rgba(255, 255, 255, 0.0)",
        plotBackgroundImage: null,
        backgroundColor: "rgba(0,0,0,0)",
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: "Total Active Power",
      pane: {
        center: ["50%", "85%"],
        size: "140%",
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || "#EEE",
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc"
        }
      },

      tooltip: {
        enabled: false
      },

      yAxis: {
        min: 0,
        max: 8,
        title: {
          text: "Total Active Power"
        }
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Total Active Power",
          data: [parseFloat(room.totalActivePower) / 1000],
          dataLabels: {
            format:
              '<div style="text-align:center"><span style="font-size:68px;color: white">{y:.1f} </span>' +
              '<span style="font-size:28px;color:white">kW</span></div>'
          },
          tooltip: {
            valueSuffix: " km/h"
          }
        }
      ],
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      }
    };
  }
  createPieChartOptions(room) {
    return {
      chart: {
        type: "pie",
        plotBackgroundColor: "rgba(255, 255, 255, 0.0)",
        plotBackgroundImage: null,
        backgroundColor: "rgba(0,0,0,0)",
        plotBorderWidth: 0,
        plotShadow: false
      },
      title: {
        text: ""
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          depth: 35,
          dataLabels: {
            enabled: true,
            format:
              '<span style="font-size:28px;color:white;border:none">{point.name} : </span>' +
              '<span style="font-size:28px;color:white;border:none">{point.y:.1f} kW</span>',
            connectorColor: "white"
          }
        }
      },
      colors: ["#FDD835", "#7E57C2", "#1E88E5", "#e53935", "#00897B"],
      series: [
        {
          type: "pie",
          name: "Active Power",
          data: [
            ["Lighting", parseFloat(room.lightingActivePower) / 1000],
            ["Plugs", parseFloat(room.plugsActivePower) / 1000],
            ["Cooling ", parseFloat(room.coolingActivePower) / 1000],
            ["Heating", parseFloat(room.heatingActivePower) / 1000],
            ["Hot Domestic Water", parseFloat(room.hotDomesticWaterActivePower) / 1000]
          ]
        }
      ]
    };
  }

  createChartHiddenDiv() {
    let chartDiv = document.createElement("div");
    chartDiv.id = "chart";
    chartDiv.style.display = "none";
    chartDiv.style.width = "1024px";
    chartDiv.style.height = "768px";
    document.body.appendChild(chartDiv);
    this.chartDiv = chartDiv;
  }

  async createCanvasChart(chartOptions) {
    const canvas = document.createElement("canvas");

    const chartSpeed = Highcharts.chart("chart", chartOptions);
    // Get the cart's SVG code
    const svg = chartSpeed.getSVG({
      exporting: {
        sourceWidth: chartSpeed.chartWidth,
        sourceHeight: chartSpeed.chartHeight
      }
    });

    canvas.height = chartSpeed.chartWidth;
    canvas.width = chartSpeed.chartHeight;

    canvg(canvas, svg);
    document.getElementById("chart").appendChild(canvas);
    return canvas;
  }
  async createGauge(canvas, width, height) {
    let texture = new window.THREE.Texture(canvas);
    texture.minFilter = window.THREE.NearestFilter;
    let material = new window.THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      alphaTest: 0.99,
      side: window.THREE.DoubleSide
    });
    let geometry = new window.THREE.PlaneBufferGeometry(width || 10, height || 8);
    let mesh = new window.THREE.Mesh(geometry, material);
    mesh.rotation.x = Math.PI / 2;
    mesh.rotation.y = Math.PI / 2;
    texture.needsUpdate = true;
    return mesh;
  }

  async updateRoomGaugeTexture(room) {
    const gaugeChartOptions = this.createGaugeChartOptions(this.room);
    const canvas = await this.createCanvasChart(gaugeChartOptions);
    const texture = new window.THREE.Texture(canvas);
    texture.minFilter = window.THREE.NearestFilter;
    this.gauges[0].material.map = texture;
    texture.needsUpdate = true;
  }
  async updateRoomPieTexture(room) {
    const pieChartOptions = this.createPieChartOptions(this.room);
    const canvas = await this.createCanvasChart(pieChartOptions);
    const texture = new window.THREE.Texture(canvas);
    texture.minFilter = window.THREE.NearestFilter;
    this.gauges[1].material.map = texture;
    texture.needsUpdate = true;
  }
  // event
  onNewData(e) {
    this.updateRoomGaugeTexture(this.room);
    this.updateRoomPieTexture(this.room);
    this.viewer.impl.sceneUpdated(true);
  }

  // firebase
  retrieveRoomFromFb() {
    const self = this;
    const rootRef = firebase.database().ref("rooms/-LEjEwgKmEP9idK2vvUr");
    this.firebaseRoomHandle = rootRef;
    rootRef.on(
      "value",
      async snapshot => {
        const isFirstLoad = self.room === null;
        self.room = snapshot.val();
        if (isFirstLoad) {
          await self.setupUI();
        }
        window.dispatchEvent(new CustomEvent("newData", {}));
      },
      error => {
        self.room = null;
      }
    );
  }
  normalizeRoomName(roomName) {
    if (!roomName) {
      return "";
    }
    return roomName
      .replace(/[\d\s]+/g, "")
      .trim()
      .toLowerCase();
  }
  selectSymbol(valueName) {
    switch (valueName) {
      case "customBrightness":
      case "customWarmness":
      case "effectiveFanSpeed":
        return " %";
      case "effectiveSetpoint":
      case "temperature":
        return " °C";
      default:
        return "";
    }
  }

  findNodeIdbyName(name) {
    let nodeList = Object.values(this.tree.nodeAccess.dbIdToIndex);
    for (let i = 1, len = nodeList.length; i < len; ++i) {
      let node_name = this.tree.getNodeName(nodeList[i]);
      if (node_name === name) {
        return nodeList[i];
      }
    }
    return null;
  }
  getFragmentWorldMatrixByNodeId(nodeId) {
    let result = {
      fragId: [],
      matrix: []
    };
    let viewer = this.viewer;
    this.tree.enumNodeFragments(nodeId, function(frag) {
      let fragProxy = viewer.impl.getFragmentProxy(viewer.model, frag);
      let matrix = new window.THREE.Matrix4();

      fragProxy.getWorldMatrix(matrix);

      result.fragId.push(frag);
      result.matrix.push(matrix);
    });
    return result;
  }
  processCamera() {
    this.gauges.forEach(gauge => {
      gauge.quaternion.copy(this.camera.quaternion);
    });
    this.viewer.impl.sceneUpdated(true);
  }
}

window.Autodesk.Viewing.theExtensionManager.registerExtension("LVSExtention", LVSExtention);
