import React, { Component } from "react";
import "./panel3d.css";
import windowBlack from "./icons/window-black.svg";
import windowGray from "./icons/window-gray.svg";
import co2Image from "./icons/co2.png";
import setPointImage from "./icons/dashboard.png";
import Ionicon from "react-ionicons";
import ValuePicker from "../ValuePicker/ValuePicker";
import PropTypes from "prop-types";

class Panel3D extends Component {
  onClose(e) {
    const { onClose } = this.props;
    onClose && onClose(e);
  }

  onShutdown(e) {
    if (this.props.onShutdown) {
      this.props.onShutdown(e);
    }
  }
  onFanSpeed(e, val) {
    if (this.props.onFanSpeed) {
      this.props.onFanSpeed(e, val);
    }
  }
  onSetPointShift(e, value) {
    if (this.props.onSetPointShift) {
      this.props.onSetPointShift(e, value);
    }
  }

  render() {
    const { data } = this.props;
    let co2 = null;
    if (data.hvac.co2) {
      co2 = (
        <div id="co2" className="co2 tooltip">
          <img src={co2Image} alt="CO2" width="27" />
          <span>{this.props.data.hvac.co2} ppm</span>
          <span className="tooltiptext">
            CO
            <sub>2</sub>
          </span>
        </div>
      );
    }

    return (
      <div className="panel-3d">
        <div className="panel-3d-header" id="panel-3d-header">
          <h1 className="title">{data.name}</h1>
          <Ionicon
            className="close"
            icon="md-close"
            fontSize="35px"
            onClick={e => this.onClose(e)}
            color="white"
          />
        </div>
        <div className="panel-3d-toolbar">
          <div className="left-group">
            <div id="temp" className="temp tooltip">
              <Ionicon
                className="thermometer"
                icon="md-thermometer"
                fontSize="20px"
                color="black"
              />
              <span>{data.hvac.temperature} °C</span>
              <span className="tooltiptext">Current temperature</span>
            </div>
            <div id="setpoint" className="setpoint tooltip">
              <img src={setPointImage} className="sunny" alt="Set Point" width="20" />
              <span>{this.props.data.hvac.effectiveSetpoint} °C</span>
              <span className="tooltiptext">Temperature setpoint</span>
            </div>
            {co2}
          </div>
          <div className="right-group">
            <div className="windows tooltip">
              <img
                src={this.props.data.hvac.openedWindow ? windowBlack : windowGray}
                alt="window"
                width="27"
              />
              <span className="tooltiptext">
                {this.props.data.hvac.openedWindow
                  ? "a window is opened"
                  : "all windows are closed"}
              </span>
            </div>
            <div className="occupation tooltip">
              <Ionicon
                className="people"
                icon="md-people"
                fontSize="27px"
                color={this.props.data.hvac.effectiveOccupation ? "black" : "lightgray"}
              />
              <span className="tooltiptext">
                {this.props.data.hvac.effectiveOccupation
                  ? "there is someone in this room "
                  : "there is no one in the room"}
              </span>
            </div>
          </div>
        </div>
        <div className="panel-3d-body">
          <div className="shutdown">
            <Ionicon
              className="power"
              icon="md-power"
              fontSize="80px"
              onClick={e => this.onShutdown(e)}
              color={this.props.data.hvac.fanCoilEnabled ? "#3f51b5" : "lightgrey"}
            />
          </div>
          <div className="setpoint">
            <ValuePicker
              minValue={-3}
              maxValue={3}
              value={this.props.data.hvac.setpointOffset}
              stepValue={1}
              onBulletClicked={(e, value) => this.onSetPointShift(e, value)}
            />
          </div>
          <div className="fan-speed">
            <div>
              <Ionicon
                className={
                  this.props.data.hvac.effectiveFanSpeed === 25
                    ? this.props.data.hvac.fanCoilEnabled
                      ? "activeFan activeFan25"
                      : "activeFan"
                    : ""
                }
                icon="md-aperture"
                fontSize="30px"
                onClick={e => this.onFanSpeed(e, 25)}
              />
            </div>
            <div>
              <Ionicon
                className={
                  this.props.data.hvac.effectiveFanSpeed === 50
                    ? this.props.data.hvac.fanCoilEnabled
                      ? "activeFan activeFan50"
                      : "activeFan"
                    : ""
                }
                icon="md-aperture"
                fontSize="35px"
                onClick={e => this.onFanSpeed(e, 50)}
              />
            </div>

            <div>
              <Ionicon
                className={
                  this.props.data.hvac.effectiveFanSpeed === 75
                    ? this.props.data.hvac.fanCoilEnabled
                      ? "activeFan activeFan75"
                      : "activeFan"
                    : ""
                }
                icon="md-aperture"
                fontSize="40px"
                onClick={e => this.onFanSpeed(e, 75)}
              />
            </div>

            <div>
              <Ionicon
                className={
                  this.props.data.hvac.effectiveFanSpeed === 100
                    ? this.props.data.hvac.fanCoilEnabled
                      ? "activeFan activeFan100"
                      : "activeFan"
                    : ""
                }
                icon="md-aperture"
                fontSize="45px"
                onClick={e => this.onFanSpeed(e, 100)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Panel3D.propTypes = {
  onClose: PropTypes.func,
  onFanSpeed: PropTypes.func,
  onShutdown: PropTypes.func,
  onSetPointShift: PropTypes.func,
  data: PropTypes.object
};
export default Panel3D;
