// MarkupExt.js
import PointIcon from "./assets/icons.png";
import React from "react";
import * as ReactDOM from "react-dom";
import Panel3d from "../components/Panel3D/Panel3D";
import firebase from "firebase/app";

class BtibMarkUp3DExtension extends window.Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
    this.tree = null;
    this.rooms = [];
    this.currentPanel = null;
    this.rootRef = null;
    this.loginSuccessed = false;
    this.isDevMode = false;
    this.isInDragMode = false;
    this.customize = this.customize.bind(this);
    // Extension specific vars
    this.raycaster = new window.THREE.Raycaster();
    this.raycaster.params.PointCloud.threshold = 5; // hit-test markup size.  Change this if markup 'hover' doesn't work
    this.size = 50.0; // markup size.  Change this if markup size is too big or small
    this.lineColor = 0xffffff; // white
    this.labelOffset = new window.THREE.Vector3(10, 10, 10); // label offset 3D line offset position
    this.xDivOffset = -0.1; // x offset position of the div label wrt 3D line.
    this.yDivOffset = 0.3; // y offset position of the div label wrt 3D line.
    this.currentPanelScale = 0.7;
    this.scene = this.viewer.impl.scene; // change this to viewer.impl.sceneAfter with transparency, if you want the markup always on top.
    this.markupItems = []; // array containing markup data
    this.pointCloud = null; // window.THREE js point-cloud mesh object
    this.line3d = null; // window.THREE js point-cloud mesh object
    this.camera = this.viewer.impl.camera;
    this.hovered = null; // index of selected pointCloud id, based on markupItems array
    this.selected = null; // index of selected pointCloud id, based on markupItems array
    this.panelPosition = null; // x,y div position of selected pointCloud. updated on mouse-move
    this.offset = null; // global offset

    // Binding methods
    this.initMesh_PointCloud = this.initMesh_PointCloud.bind(this);
    this.processCamera = this.processCamera.bind(this);

    this.onMarkupMove = this.onMarkupMove.bind(this);
    this.onNewData = this.onNewData.bind(this);
    this.onMarkupClicked = this.onMarkupClicked.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);

    this.setMarkupData = this.setMarkupData.bind(this);
    this.initMesh_Line = this.initMesh_Line.bind(this);
    this.update_Line = this.update_Line.bind(this);
    this.update_DivLabel = this.update_DivLabel.bind(this);
    this.setupUI = this.setupUI.bind(this);

    // Shaders
    this.vertexShader = `
        uniform float size;
        varying vec3 vColor;
        void main() {
            vColor = color;
            vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
            gl_PointSize = size * ( size / (length(mvPosition.xyz) + 1.0) );
            gl_Position = projectionMatrix * mvPosition;
        }
    `;

    this.fragmentShader = `
        uniform sampler2D tex;
        varying vec3 vColor;
        void main() {
            gl_FragColor = vec4( vColor.x, vColor.x, vColor.x, 1.0 );
            gl_FragColor = gl_FragColor * texture2D(tex, vec2((gl_PointCoord.x+vColor.y*1.0)/4.0, 1.0-gl_PointCoord.y));
            if (gl_FragColor.w < 0.5) discard;
        }
    `;

    // Sample data
    this.pointsData = [];
  }

  load() {
    this.viewer.addEventListener(window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.customize);
    this.viewer.addEventListener(
      window.Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
      this.getObjectTree
    );
    if (this.viewer.isExtensionLoaded("BtibFirebaseExtension")) {
      if (!this.viewer.isExtensionActive()) {
        this.viewer.activateExtension("BtibFirebaseExtension");
      }
    } else {
      return false;
    }
    this.customize();
    return true;
  }
  unload() {
    if (this.pointCloud) {
      this.scene.remove(this.pointCloud);
    }
    if (this.panel) {
      document.body.removeChild(this.panel);
    }
    this.viewer.container.removeEventListener("onMarkupMove", this.onMarkupMove, false);
    window.removeEventListener("newData", this.onNewData, false);
    window.removeEventListener("onMarkupClick", this.onMarkupClicked, false);
    this.viewer.container.removeEventListener("mousedown", this.onMouseDown, true);
    this.viewer.container.removeEventListener("touchstart", this.onTouchStart, false);
    this.viewer.container.removeEventListener("touchmove", this.onTouchMove, false);
    this.viewer.container.removeEventListener("mousewheel", this.onMouseMove, true);
    this.viewer.removeEventListener(window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.customize);
    if (this.rootRef) {
      this.rootRef.off("value");
    }
    this.viewer.removeEventListener(
      window.Autodesk.Viewing.CAMERA_CHANGE_EVENT,
      this.processCamera
    );
    return true;
  }

  customize() {
    // Specific code goes here
    this.offset = this.viewer.model.getData().globalOffset; // use global offset to align pointCloud with lmv scene

    this.tree = this.viewer.model.getData().instanceTree;
    this.cam = this.viewer.getCamera();
    this.viewer.addEventListener(window.Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.processCamera);
    this.viewer.disableSelection(true);
    this.viewer.disableHighlight(true);
    this.retrieveRoomFromFb();
    return true;
  }

  movePanel(position) {
    if (!this.panel) {
      return;
    }
    this.panel.style.left = ((position.x + 1) / 2) * window.innerWidth + "px";
    this.panel.style.top = (-(position.y - 1) / 2) * window.innerHeight + "px";
  }
  setupUI() {
    const self = this;
    const nodeId = this.findNodeIdbyName("Rooms");
    this.tree.enumNodeChildren(nodeId, child => {
      const roomName = self.tree.getNodeName(child);
      const roomData = self.getRoomByName(roomName);
      let solid = null;
      self.tree.enumNodeChildren(child, subChild => (solid ? 0 : (solid = subChild)));
      if (roomData) {
        let transMat = self.getFragmentWorldMatrixByNodeId(solid).matrix[0];
        // continue if it has transformation Matrix (meaning it is not a "group node")
        const pos = new window.THREE.Vector3();
        pos.setFromMatrixPosition(transMat);
        self.markupItems.push({
          roomName,
          x: roomName.toLowerCase().contains("salle principale") ? pos.x - 5 : pos.x,
          y: pos.y,
          z: pos.z + 4
        });
      }
    });
    this.setMarkupData();
    // listen for the 'Markup' event, to re-position our <DIV> POPUP box
    window.addEventListener("onMarkupMove", this.onMarkupMove, false);
    window.addEventListener("newData", this.onNewData, false);
    window.addEventListener("onMarkupClick", this.onMarkupClicked, false);
    this.viewer.container.addEventListener("mousedown", this.onMouseDown, true);
    this.viewer.container.addEventListener("touchstart", this.onTouchStart, false);
    this.viewer.container.addEventListener("touchmove", this.onTouchMove, false);
    this.viewer.container.addEventListener("mousewheel", this.onMouseMove, true);
  }

  onMarkupMove(e) {
    this.movePanel(e.detail);
  }
  onNewData(e) {
    if (this.selectedRoom) {
      this.selectedRoom = this.getRoomByName(this.selectedRoom.name);
    }
    if (this.panel) {
      const left = this.panel.style.left;
      const top = this.panel.style.top;
      document.body.removeChild(this.panel);
      this.panel = this.createPanel(this.selectedRoom);
      document.body.appendChild(this.panel);
      this.panel.style.left = left;
      this.panel.style.top = top;
    }
  }

  onMarkupClicked(e) {
    if (this.panel) {
      document.body.removeChild(this.panel);
    }
    this.panel = this.createPanel(this.selectedRoom);
    document.body.appendChild(this.panel);
    this.movePanel(e.detail);
  }

  onTouchMove(e) {
    //this.onMouseMove(e.changedTouches[0]);
  }
  updateHitTest(event) {
    // on mouse move event, check if ray hit with pointcloud, move selection cursor
    // https://stackoverflow.com/questions/28209645/raycasting-involving-individual-points-in-a-window.THREE-js-pointcloud
    if (!this.pointCloud) {
      return;
    }

    let x = (event.clientX / window.innerWidth) * 2 - 1;
    let y = -(event.clientY / window.innerHeight) * 2 + 1;
    let vector = new window.THREE.Vector3(x, y, 0.5).unproject(this.camera);
    this.raycaster.set(this.camera.position, vector.sub(this.camera.position).normalize());
    let nodes = this.raycaster.intersectObject(this.pointCloud);
    if (nodes.length > 0) {
      if (this.hovered) {
        this.geometry.colors[this.hovered].r = 1.0;
      }
      this.hovered = nodes[0].index;
      this.hoveredNode = nodes[0];
      this.geometry.colors[this.hovered].r = 2.0;
      this.geometry.colorsNeedUpdate = true;
      this.viewer.impl.invalidate(true);
    } else {
      this.hovered = false;
    }
  }

  // Load markup points into Point Cloud
  setMarkupData() {
    this.geometry = new window.THREE.Geometry();
    this.markupItems.forEach(item => {
      let point = new window.THREE.Vector3(item.x, item.y, item.z);
      point.roomName = item.roomName;
      this.geometry.vertices.push(point);
      this.geometry.colors.push(new window.THREE.Color(1.0, 0, 0)); // icon = 0..2 position in the horizontal icons.png sprite sheet
    });
    this.initMesh_PointCloud();
    this.initMesh_Line();
  }

  getRommNameFromCloudPoints(index) {
    return this.hoveredNode.object.geometry.vertices[index].roomName;
  }
  initMesh_PointCloud() {
    if (this.pointCloud) {
      this.scene.remove(this.pointCloud);
    }

    //replace existing pointCloud Mesh
    else {
      // create new point cloud material
      let texture = window.THREE.ImageUtils.loadTexture(PointIcon);
      let material = new window.THREE.ShaderMaterial({
        vertexColors: window.THREE.VertexColors,
        fragmentShader: this.fragmentShader,
        vertexShader: this.vertexShader,
        depthWrite: true,
        depthTest: true,
        uniforms: {
          size: {
            type: "f",
            value: this.size
          },
          tex: {
            type: "t",
            value: texture
          }
        }
      });
      this.pointCloud = new window.THREE.PointCloud(this.geometry, material);
      this.pointCloud.position.sub(this.offset);
      this.scene.add(this.pointCloud);
    }
  }

  initMesh_Line() {
    let geom = new window.THREE.Geometry();
    geom.vertices.push(new window.THREE.Vector3(0, 0, 0), new window.THREE.Vector3(1, 1, 1));
    this.line3d = new window.THREE.Line(
      geom,
      new window.THREE.LineBasicMaterial({
        color: this.lineColor,
        linewidth: 60.0
      })
    );
    this.line3d.position.sub(this.offset);
    this.scene.add(this.line3d);
  }

  update_Line() {
    if (!this.line3d) {
      this.initMesh_Line();
    }
    let position = this.pointCloud.geometry.vertices[this.selected].clone();
    this.line3d.geometry.vertices[0] = position;
    this.line3d.geometry.vertices[1].set(
      position.x + this.labelOffset.x * Math.sign(position.x),
      position.y + this.labelOffset.y,
      position.z + this.labelOffset.z
    );
    this.line3d.geometry.verticesNeedUpdate = true;
  }

  update_DivLabel(eventName) {
    if (!this.line3d) {
      return;
    }
    let position = this.line3d.geometry.vertices[1].clone().sub(this.offset);
    this.panelPosition = position.project(this.camera);
    if (this.selected >= 0) {
      window.dispatchEvent(
        new CustomEvent(eventName, {
          detail: {
            x: this.panelPosition.x + this.xDivOffset,
            y: this.panelPosition.y + this.yDivOffset
          }
        })
      );
    }
  }

  // Dispatch Message when a point is clicked
  onMouseMove(event) {
    if (this.isInDragMode) {
      return;
    }
    this.update_DivLabel("onMarkupMove");
    this.updateHitTest(event);
  }

  onTouchStart(e) {
    //this.onMouseDown(e.changedTouches[0]);
  }
  onMouseDown(event) {
    const self = this;
    this.viewer.container.addEventListener("mousemove", self.onMouseMove);
    this.viewer.container.addEventListener(
      "mouseup",
      e => {
        self.viewer.container.removeEventListener("mousemove", self.onMouseMove);
      },
      true
    );
    this.updateHitTest(event);
    if (this.hovered === false) {
      return;
    }
    const roomName = this.getRommNameFromCloudPoints(this.hovered);
    const roomData = this.getRoomByName(roomName);
    this.selected = this.hovered;
    this.selectedRoom = roomData;
    this.update_Line();
    this.update_DivLabel("onMarkupClick");
    this.viewer.impl.invalidate(true);
    this.viewer.clearSelection();
  }
  createPanel(roomData) {
    const self = this;
    const ui = document.createElement("div");
    ui.id = "panel-3d";
    ui.style.cssText = `
            display: block;
            position: absolute;
            opacity:0.9;
            z-index: 2;`;

    function dragElement(elmnt) {
      let pos1 = 0,
        pos2 = 0,
        pos3 = 0,
        pos4 = 0;
      function elementDrag(e) {
        e = e || window.event;
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = elmnt.offsetTop - pos2 + "px";
        elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        const worldPos = self.viewer.clientToWorld(
          elmnt.offsetLeft - pos1 + elmnt.offsetWidth / 2,
          elmnt.offsetTop - pos2 + elmnt.offsetHeight / 2,
          false
        );
        if (worldPos === null) {
          return;
        }
        self.line3d.geometry.vertices[1].set(worldPos.point.x, worldPos.point.y, worldPos.point.z);
        self.line3d.geometry.verticesNeedUpdate = true;
        self.viewer.impl.invalidate(true);
      }

      function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
        self.isInDragMode = false;
      }
      function dragMouseDown(e) {
        e = e || window.event;
        self.isInDragMode = true;
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
      }

      if (elmnt.querySelector("#panel-3d-header")) {
        /* if present, the header is where you move the DIV from:*/
        elmnt.querySelector("#panel-3d-header").onmousedown = dragMouseDown;
      } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
      }
    }
    ReactDOM.render(
      <Panel3d
        data={roomData}
        onShutdown={e => this.onShutdown(e)}
        onClose={e => this.onClose(e)}
        onFanSpeed={(e, val) => this.onFanSpeed(e, val)}
        onSetPointShift={(e, value) => this.onSetPointShift(e, value)}
      />,
      ui
    );
    dragElement(ui);
    return ui;
  }

  onSetPointShift(e, value) {
    if (this.viewer.isLogged() || this.isDevMode) {
      const rootRef = firebase.database().ref();
      const storesRef = rootRef
        .child("commands")
        .child(this.selectedRoom.niagaraInstance)
        .push();
      storesRef.set({
        handleOrd: this.selectedRoom.hvac.niagaraHandleOrd,
        name: "setPointShiftCommand",
        value: parseInt(value, 10)
      });
    } else {
      this.showErrorMessage("You are not logged in try to login from the menu on the top left");
    }
  }
  onShutdown(e) {
    if (this.viewer.isLogged() || this.isDevMode) {
      const rootRef = firebase.database().ref();
      const storesRef = rootRef
        .child("commands")
        .child(this.selectedRoom.niagaraInstance)
        .push();
      storesRef.set({
        handleOrd: this.selectedRoom.hvac.niagaraHandleOrd,
        name: "hvacSwitchCommand",
        value: !this.selectedRoom.hvac.fanCoilEnabled
      });
    } else {
      this.showErrorMessage("You are not logged in try to login from the menu on the top left");
    }
  }

  onClose(event) {
    if (this.panel) {
      document.body.removeChild(this.panel);
    }
    if (this.line3d) {
      this.scene.remove(this.line3d);
      this.viewer.impl.invalidate(true);
    }
    this.line3d = null;
    this.panel = null;
    this.selectedRoom = null;
  }

  onFanSpeed(e, val) {
    if (this.viewer.isLogged() || this.isDevMode) {
      const rootRef = firebase.database().ref();
      const storesRef = rootRef
        .child("commands")
        .child(this.selectedRoom.niagaraInstance)
        .push();
      storesRef.set({
        handleOrd: this.selectedRoom.hvac.niagaraHandleOrd,
        name: "fanSpeedCommand",
        value: val
      });
    } else {
      this.showErrorMessage("You are not logged in try to login from the menu on the top left");
    }
  }

  // firebase
  retrieveRoomFromFb() {
    const self = this;
    this.rootRef = firebase.database().ref("rooms");
    this.rootRef.on(
      "value",
      function(snapshot) {
        const isFirstLoad = self.rooms.length === 0;
        const roomsSnapshot = snapshot.val();
        self.rooms = Object.keys(roomsSnapshot).map(key => roomsSnapshot[key]);
        self.rooms = self.rooms.filter(room => room.name && !room.name.contains("local technique"));
        window.dispatchEvent(new CustomEvent("newData", {}));
        if (isFirstLoad) {
          self.setupUI();
        }
      },
      function(error) {
        self.rooms = [];
      }
    );
  }
  normalizeRoomName(roomName) {
    if (!roomName) {
      return "";
    }
    return roomName
      .replace(/[\d\s]+/g, "")
      .trim()
      .toLowerCase();
  }
  selectSymbol(valueName) {
    switch (valueName) {
      case "customBrightness":
      case "customWarmness":
      case "effectiveFanSpeed":
        return " %";
      case "effectiveSetpoint":
      case "temperature":
        return " °C";
      default:
        return "";
    }
  }
  getRoomByName(name) {
    for (let i = 0, rLen = this.rooms.length; i < rLen; i++) {
      let n1 = this.normalizeRoomName(this.rooms[i].name);
      let n2 = this.normalizeRoomName(name);
      if (n1.contains(n2) || n2.contains(n1)) {
        return this.rooms[i];
      }
    }
    return null;
  }
  findNodeIdbyName(name) {
    let nodeList = Object.values(this.tree.nodeAccess.dbIdToIndex);
    for (let i = 1, len = nodeList.length; i < len; ++i) {
      let node_name = this.tree.getNodeName(nodeList[i]);
      if (node_name === name) {
        return nodeList[i];
      }
    }
    return null;
  }
  getFragmentWorldMatrixByNodeId(nodeId) {
    let result = {
      fragId: [],
      matrix: []
    };
    let viewer = this.viewer;
    this.tree.enumNodeFragments(nodeId, function(frag) {
      let fragProxy = viewer.impl.getFragmentProxy(viewer.model, frag);
      let matrix = new window.THREE.Matrix4();

      fragProxy.getWorldMatrix(matrix);

      result.fragId.push(frag);
      result.matrix.push(matrix);
    });
    return result;
  }

  showErrorMessage(msg) {
    let snackbarContainer = document.querySelector("#snackbar");
    let data = {
      message: msg,
      timeout: 3000,
      actionText: "Ok"
    };
    snackbarContainer.MaterialSnackbar.showSnackbar(data);
  }

  processCamera() {}
}

window.Autodesk.Viewing.theExtensionManager.registerExtension(
  "BtibMarkUp3DExtension",
  BtibMarkUp3DExtension
);
