import React, { Component } from "react";
import ForgeViewer from "../ForgeViewer/ForgeViewer";
import "./App.css";
import logo from "./btib-logo.png";
import firebase from "firebase/app";

class App extends Component {
  constructor(props) {
    super(props);
    this.viewer = null;
    this.dialog = null;
    this.state = {
      extensions: ["BtibFirebaseExtension"],
      remoteLinkActive: true,
      colorTempLinkActive: false,
      isLogged: false,
      username: ""
    };
  }

  onRemoteLinkChanged(event) {
    if (event.target.checked) {
      this.viewer
        .loadExtension("BtibMarkUp3DExtension")
        .then(ext => {
          this.viewer.activateExtension("BtibMarkUp3DExtension");
        })
        .catch(err => {
          throw err;
        });
    } else {
      if (this.viewer.isExtensionLoaded("BtibMarkUp3DExtension")) {
        this.viewer.unloadExtension("BtibMarkUp3DExtension");
      }
    }
  }

  onTemperatureLinkChanged(event) {
    if (event.target.checked) {
      this.viewer
        .loadExtension("BtibColorTempExtension")
        .then(ext => {
          this.viewer.activateExtension("BtibColorTempExtension");
        })
        .catch(err => {
          throw err;
        });
    } else {
      if (this.viewer.isExtensionLoaded("BtibColorTempExtension")) {
        this.viewer.unloadExtension("BtibColorTempExtension");
      }
    }
  }

  onGaugeLinkClicked(event) {
    if (event.target.checked) {
      this.viewer
        .loadExtension("LVSExtention")
        .then(ext => {
          this.viewer.activateExtension("LVSExtention");
        })
        .catch(err => {
          throw err;
        });
    } else {
      if (this.viewer.isExtensionLoaded("LVSExtention")) {
        this.viewer.unloadExtension("LVSExtention");
      }
    }
  }

  onViewerReady(event) {
    this.viewer = event.target;
    this.viewer.isLogged = () => {
      if (this.state.isLogged) {
        return { username: this.state.username };
      }
      return false;
    };
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      this.setState(prevState => {
        return {
          isLogged: true,
          username: currentUser.email
        };
      });
    }
  }

  onSignInClicked(event) {
    let dialog = document.querySelector("#dialog-login");
    dialog.showModal();
  }
  async onSignIn(e) {
    const usernameInput = document.querySelector("#username");
    const passwordInput = document.querySelector("#password");
    const errorMessage = document.querySelector("#login-error");
    const username = usernameInput.value;
    const password = passwordInput.value;
    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
      await firebase.auth().signInWithEmailAndPassword(username, password);
      this.setState(prevState => {
        return {
          isLogged: true,
          username
        };
      });

      errorMessage.style.visibility = "hidden";
      let dialog = document.querySelector("#dialog-login");
      dialog.close();
    } catch (error) {
      errorMessage.style.visibility = "visible";
      throw error;
    }
  }

  async onLogout(e) {
    try {
      await firebase.auth().signOut();
      const usernameInput = document.querySelector("#username");
      const passwordInput = document.querySelector("#password");
      usernameInput.value = "";
      passwordInput.value = "";
      this.setState(prevState => {
        return {
          isLogged: false,
          username: ""
        };
      });
    } catch (error) {
      throw error;
    }
  }

  render() {
    let logginStatus = null;
    if (this.state.isLogged) {
      logginStatus = (
        <li className="mdl-list__item">
          <span className="mdl-list__item-primary-content capitalize">
            <i className="material-icons mdl-list__item-icon">person</i>
            <span>{this.state.username}</span>
            <i
              className="material-icons mdl-list__item-icon logout"
              onClick={e => this.onLogout(e)}
            >
              logout
            </i>
          </span>
        </li>
      );
    } else {
      logginStatus = (
        <li className="mdl-list__item">
          <span className="mdl-list__item-primary-content">
            <i className="material-icons mdl-list__item-icon">person</i>
            <button
              className="mdl-button mdl-js-button mdl-button--raised mdl-button--colored"
              onClick={e => this.onSignInClicked(e)}
            >
              SIGN IN
            </button>
          </span>
        </li>
      );
    }
    return (
      <div className="demo-layout-transparent mdl-layout mdl-js-layout">
        <header className="mdl-layout__header">
          <div className="mdl-layout__header-row">
            <span className="mdl-layout-title">
              <img src={logo} alt="logo" />
            </span>
            <div className="mdl-layout-spacer" />
            <nav className="mdl-navigation">
              <a
                className="mdl-navigation__link"
                href="https://www.btib.fr"
                target="_blank"
                rel="noopener noreferrer"
              >
                About BTIB
              </a>
              <a
                className="mdl-navigation__link"
                href="https://www.btib.fr/contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                Contact US
              </a>
            </nav>
          </div>
        </header>
        <div className="mdl-layout__drawer">
          {logginStatus}
          <nav className="mdl-navigation">
            <a className="mdl-navigation__link" href="#!">
              <label
                className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                htmlFor="checkbox-1"
              >
                <input
                  type="checkbox"
                  id="checkbox-1"
                  className="mdl-checkbox__input"
                  onChange={event => this.onRemoteLinkChanged(event)}
                />
                <span className="mdl-checkbox__label">Remote Control</span>
              </label>
            </a>
            <a className="mdl-navigation__link" href="#!">
              <label
                className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                htmlFor="checkbox-2"
              >
                <input
                  type="checkbox"
                  id="checkbox-2"
                  className="mdl-checkbox__input"
                  onChange={e => this.onTemperatureLinkChanged(e)}
                />
                <span className="mdl-checkbox__label">Temperature Material</span>
              </label>
            </a>
            <a className="mdl-navigation__link" href="#!">
              <label
                className="mdl-checkbox mdl-js-checkbox mdl-js-ripple-effect"
                htmlFor="checkbox-3"
              >
                <input
                  type="checkbox"
                  id="checkbox-3"
                  className="mdl-checkbox__input"
                  onChange={e => this.onGaugeLinkClicked(e)}
                />
                <span className="mdl-checkbox__label">LVS Extention</span>
              </label>
            </a>
          </nav>
        </div>
        <main className="mdl-layout__content">
          <ForgeViewer
            viewerID="myViewer"
            extensions={this.state.extensions}
            onViewerReady={event => this.onViewerReady(event)}
          />
          <button
            className="mdl-button mdl-js-button mdl-button--fab mdl-js-ripple-effect my-fab"
            onClick={e => {
              let dialog = document.querySelector("#dialog-about");
              dialog.showModal();
            }}
          >
            <i className="material-icons">feedback</i>
          </button>
          <dialog className="mdl-dialog" id="dialog-about">
            <h4 className="mdl-dialog__title">BTIB BIM Demo</h4>
            <div className="mdl-dialog__content">
              <p>
                <strong>BTIB</strong> is a specialist of the Niagara Framework, a Building Operating
                System dedicated to Smart Buildings. It allows the acquisition of the data from the
                field and the cloud as an IoT platform, to program logic and automation in order to
                provide robust monitoring and management of Smart Buildings. Deployments of this
                solution are in factories, hospitals, universities, warehouses and other large
                campus-style layouts.
              </p>
              <p>
                This <strong>demo</strong> shows real time data monitored by Niagara in BTIB
                headquarters and various visualizations and functionalities. It allows the user (a
                Facility Manager for example) to visualize the difference between a room temperature
                and its setpoint to detect easily some huge differences. It also allows interactions
                with the room hvac controls (switch, setpoint, fan speed). The demo is a proof of
                concept to demonstrate the deployment automation of such interfaces based on
                structured data from Smart Buildings. Floors and rooms naming and structure are
                synchronized between the BIM Model and the Niagara Platform allowing the data
                acquired in the building to be fully compatible with a BIM Application. With
                structured data, it is not necessary to map each data to the BIM Model, it can be
                automatic. It is intended to lower deployment costs.
              </p>
            </div>
            <div className="mdl-dialog__actions">
              <button
                type="button"
                className="mdl-button close"
                onClick={e => {
                  let dialog = document.querySelector("#dialog-about");
                  dialog.close();
                }}
              >
                Close
              </button>
            </div>
          </dialog>
          <dialog className="mdl-dialog" id="dialog-login">
            <h4 className="mdl-dialog__title">SIGN IN</h4>
            <div className="mdl-dialog__content">
              <div className="mdl-grid">
                <div className="mdl-cell mdl-cell--3-col" />
                <div className="mdl-cell mdl-cell--6-col person-icon">
                  <i className="material-icons mdl-list__item-icon">person</i>
                </div>
                <div className="mdl-cell mdl-cell--3-col" />
              </div>
              <div className="mdl-grid">
                <div className="mdl-cell mdl-cell--12-col">
                  <form action="#">
                    <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                      <input className="mdl-textfield__input" type="text" id="username" />
                      <label className="mdl-textfield__label" htmlFor="username">
                        Username
                      </label>
                    </div>
                    <div className="mdl-textfield mdl-js-textfield  mdl-textfield--floating-label">
                      <input className="mdl-textfield__input" type="password" id="password" />
                      <label className="mdl-textfield__label" htmlFor="password">
                        Password
                      </label>
                    </div>
                    <span className="mdl-textfield__error" id="login-error">
                      Username or password is incorrect
                    </span>
                  </form>
                </div>
              </div>
            </div>
            <div className="mdl-dialog__actions mdl-dialog__actions--full-width">
              <button
                type="button"
                className="mdl-button mdl-js-button mdl-button--raised close"
                onClick={e => {
                  this.onSignIn(e);
                }}
              >
                sign in
              </button>
              <button
                type="button"
                className="mdl-button mdl-js-button mdl-button--raised "
                onClick={e => {
                  let dialog = document.querySelector("#dialog-login");
                  dialog.close();
                }}
              >
                cancel
              </button>
            </div>
          </dialog>
          <div id="snackbar" className="mdl-js-snackbar mdl-snackbar">
            <div className="mdl-snackbar__text" />
            <button className="mdl-snackbar__action" type="button" />
          </div>
        </main>
      </div>
    );
  }
}

export default App;
