import React, { Component } from "react";
import "./value-picker.css";

class ValuePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBulletIndex: props.value
    };
  }

  onBulletClicked(e, index) {
    if (this.props.onBulletClicked) {
      this.props.onBulletClicked(e, index);
    }
  }

  render() {
    let bullets = [];
    for (
      let index = this.props.minValue;
      index <= this.props.maxValue;
      index += this.props.stepValue
    ) {
      const span = (
        <span
          className="bullet"
          key={index}
          style={{
            backgroundColor:
              this.state.activeBulletIndex === index
                ? this.props.bulletColorActive
                : this.props.bulletColor,
            width: this.props.bulletSize,
            height: this.props.bulletSize,
            borderRadius: this.props.bulletSize / 2
          }}
          onClick={e => this.onBulletClicked(e, index)}
        />
      );
      bullets = [bullets, span];
    }
    return (
      <div className="value-picker">
        <span
          className="min-value"
          style={{
            color: this.props.textColor,
            fontSize: this.props.textSize,
            width: this.props.bulletSize,
            height: this.props.bulletSize
          }}
        >
          {this.props.minValue}
        </span>
        {bullets}
        <span
          className="max-value"
          style={{
            color: this.props.textColor,
            fontSize: this.props.textSize,
            width: this.props.bulletSize,
            height: this.props.bulletSize
          }}
        >
          {this.props.maxValue > 0
            ? "+" + this.props.maxValue
            : "" + this.props.maxValue}
        </span>
      </div>
    );
  }
}

ValuePicker.defaultProps = {
  minValue: 0,
  maxValue: 5,
  stepValue: 1,
  textColor: "#000000",
  textSize: 18,
  bulletSize: 20,
  bulletColor: "lightgray",
  bulletColorActive: "#3f51b5"
};

export default ValuePicker;
