import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

class BtibFirebaseExtension extends window.Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
    const config = {
      apiKey: "AIzaSyDOaElqjGiFZPMygZbD04S7HyYrkpgPdb8",
      authDomain: "activeoffice-885b3.firebaseapp.com",
      databaseURL: "https://activeoffice-885b3.firebaseio.com",
      projectId: "activeoffice-885b3",
      storageBucket: "activeoffice-885b3.appspot.com",
      messagingSenderId: "44661379138"
    };
    firebase.initializeApp(config);
  }
  load() {
    return true;
  }

  unload() {
    return true;
  }
}
window.Autodesk.Viewing.theExtensionManager.registerExtension(
  "BtibFirebaseExtension",
  BtibFirebaseExtension
);
