import React, { Component } from "react";
import PropTypes from "prop-types";

class ForgeViewer extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    let id = this.props.viewerID;
    let extensionList = this.props.extensions || [];
    this.state = {
      viewerID: id,
      extensionList: extensionList
    };
  }

  initViewer() {
    const { viewerID, extensionList } = this.state;
    let viewerApp;
    let viewer = null;
    const self = this;
    let options = {
      env: "AutodeskProduction",
      getAccessToken: function(onGetAccessToken) {
        let token_fetcher = "https://9irt90dm6j.execute-api.us-east-1.amazonaws.com/prod";
        let xmlHttp = new XMLHttpRequest();
        xmlHttp.open("GET", token_fetcher, false);
        xmlHttp.send(null);
        let data = JSON.parse(xmlHttp.responseText);

        let accessToken = data["access_token"];
        let expireTimeSeconds = data["expires_in"];
        onGetAccessToken(accessToken, expireTimeSeconds);
      },
      useADP: false
    };
    let documentId =
      "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bmlhZ2FyYS1wb2MvQk9mZmljZV9uZXdfUm9vbXMubndk";
    function onDocumentLoadFailure(viewerErrorCode) {
      throw new Error("onDocumentLoadFailure() - errorCode:" + viewerErrorCode);
    }

    function onItemLoadSuccess(active_viewer, item) {
      viewer = active_viewer;
      viewer.addEventListener(window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT, event => {
        self.onViewerReady(event);
      });
    }

    function onItemLoadFail(errorCode) {
      throw new Error("onItemLoadFail() - errorCode:" + errorCode);
    }
    function onDocumentLoadSuccess(doc) {
      // We could still make use of Document.getSubItemsWithProperties()
      // However, when using a ViewingApplication, we have access to the **bubble** attribute,
      // which references the root node of a graph that wraps each object from the Manifest JSON.
      let viewables = viewerApp.bubble.search({
        type: "geometry"
      });
      if (viewables.length === 0) {
        throw new Error("Document contains no viewables.");
      }

      // Choose any of the avialble viewables
      viewerApp.selectItem(viewables[0].data, onItemLoadSuccess, onItemLoadFail);
    }
    window.Autodesk.Viewing.Initializer(options, function onInitialized() {
      viewerApp = new window.Autodesk.Viewing.ViewingApplication(viewerID);
      let config3d = {
        extensions: extensionList
      };
      viewerApp.registerViewer(
        viewerApp.k3D,
        window.Autodesk.Viewing.Private.GuiViewer3D,
        config3d
      );
      viewerApp.loadDocument(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
      viewer = viewerApp.getCurrentViewer();
    });
  }

  onViewerReady(event) {
    this.props.onViewerReady(event);
  }
  render() {
    const { viewerID } = this.state;
    return <div id={viewerID} className="ForgeViewer" />;
  }

  componentDidMount() {
    this.initViewer();
  }
}

ForgeViewer.propTypes = {
  viewerID: PropTypes.string,
  extensions: PropTypes.array,
  onViewerReady: PropTypes.func
};
export default ForgeViewer;
