// import libs
// import others
import React from "react";
import ReactDOM from "react-dom";
import "./viewer-extensions/libs/CSS3DRenderer";
import "./viewer-extensions/BtibFirebaseExtension";
import "./viewer-extensions/BtibMarkUp3DExtension";
import "./viewer-extensions/BtibColorTempExtension";
import "./viewer-extensions/BtibGauge3DExtension";
import "./viewer-extensions/MetadataExtention";
import "./viewer-extensions/LVSExtention";
import "./index.css";
import App from "./components/App/App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
