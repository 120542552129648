class MetadataExtention extends window.window.Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
  }

  load() {
    return true;
  }

  unload() {
    return true;
  }
}

window.window.Autodesk.Viewing.theExtensionManager.registerExtension(
  "MetadataExtention",
  MetadataExtention
);
