import firebase from "firebase/app";

class BtibColorTempExtension extends window.Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
    this.tree = null;
    this.rooms = [];
    this.tempPanel = null;
    this.loginSuccessed = false;
    this.customize = this.customize.bind(this);
    // Extension specific vars
    this.raycaster = new window.THREE.Raycaster();
    this.raycaster.params.PointCloud.threshold = 5; // hit-test markup size.  Change this if markup 'hover' doesn't work
    this.colorPresets = [
      "#D32F2F",
      "#F44336",
      "#E57373",
      "#FFCDD2",
      "#E8EAF6",
      "#64B5F6",
      "#1E88E5",
      "#1565C0",
      "#0D47A1"
    ];
    this.customMaterialList = [];
    this.defaultMaterial = new window.THREE.MeshPhongMaterial({
      side: window.THREE.DoubleSide,
      reflectivity: 0.0,
      flatShading: true,
      transparent: true,
      opacity: 0,
      color: "#FFFFFF"
    });
    this.camera = this.viewer.impl.camera;
    this.scene = this.viewer.impl.scene; // change this to viewer.impl.sceneAfter with transparency, if you want the markup always on top.
    this.setupUI = this.setupUI.bind(this);
    this.createMaterial = this.createMaterial.bind(this);
    this.onNewData = this.onNewData.bind(this);
    this.updateTemperatureColor = this.updateTemperatureColor.bind(this);
  }

  load() {
    this.viewer.addEventListener(window.Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.customize);
    this.viewer.addEventListener(
      window.Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
      this.getObjectTree
    );
    if (this.viewer.isExtensionLoaded("BtibFirebaseExtension")) {
      if (!this.viewer.isExtensionActive()) {
        this.viewer.activateExtension("BtibFirebaseExtension");
      }
    } else {
      return false;
    }
    this.customize();
    return true;
  }
  unload() {
    this.clearMaterials();
    if (this.tempPanel) {
      document.body.removeChild(this.tempPanel);
    }
    window.removeEventListener("newData", this.onNewData, false);
    return true;
  }

  customize() {
    // Specific code goes here
    this.colorPresets.forEach(color => this.customMaterialList.push(this.createMaterial(color)));
    this.tree = this.viewer.model.getData().instanceTree;
    this.cam = this.viewer.getCamera();
    this.viewer.disableSelection(true);
    this.viewer.disableHighlight(true);
    this.retrieveRoomFromFb();
    return true;
  }

  setupUI() {
    this.updateTemperatureColor();
    this.createPanel();
    window.addEventListener("newData", this.onNewData, false);
  }

  updateTemperatureColor() {
    const self = this;
    let scaleRatio = 0.995; // this was determined as optimal through visual inspection
    const nodeId = this.findNodeIdbyName("Rooms");
    this.tree.enumNodeChildren(nodeId, child => {
      const roomName = self.tree.getNodeName(child);
      const roomData = self.getRoomByName(roomName);
      let solid = null;
      self.tree.enumNodeChildren(child, subChild => (solid ? 0 : (solid = subChild)));
      if (roomData) {
        let diff = roomData.hvac.effectiveSetpoint - roomData.hvac.temperature;
        if (isNaN(diff)) {
          return;
        }
        if (diff > 4) {
          diff = 4;
        } else if (diff < -4) {
          diff = -4;
        }
        let colorPosition = diff + 4;
        let temperatureMaterial = this.customMaterialList[colorPosition];
        this.tree.enumNodeFragments(solid, fragId => {
          self.viewer.model.getFragmentList().setMaterial(fragId, temperatureMaterial);
          let fragProxy = self.viewer.impl.getFragmentProxy(self.viewer.model, fragId);

          // to rescale and remove the z-fighting
          fragProxy.scale = new window.THREE.Vector3(scaleRatio, scaleRatio, scaleRatio);

          fragProxy.updateAnimTransform();
        });
      }
    });
    self.viewer.impl.invalidate(true);
  }

  clearMaterials() {
    const self = this;
    let scaleRatio = 0.995; // this was determined as optimal through visual inspection
    const nodeId = this.findNodeIdbyName("Rooms");
    this.tree.enumNodeChildren(nodeId, child => {
      let solid = null;
      self.tree.enumNodeChildren(child, subChild => (solid ? 0 : (solid = subChild)));

      this.tree.enumNodeFragments(solid, fragId => {
        self.viewer.model.getFragmentList().setMaterial(fragId, this.defaultMaterial);
        let fragProxy = self.viewer.impl.getFragmentProxy(self.viewer.model, fragId);
        // to rescale and remove the z-fighting
        fragProxy.scale = new window.THREE.Vector3(scaleRatio, scaleRatio, scaleRatio);
        fragProxy.updateAnimTransform();
      });
    });
    self.viewer.impl.invalidate(true);
  }
  createPanel() {
    const self = this;
    const tempPanel = (this.tempPanel = document.createElement("div"));

    tempPanel.id = "tempPanel";
    // tempPanel.className = "infoPanel";
    tempPanel.style.cssText = `
            left: 15px;
            top: 80px;
            width: 100px;
            position: absolute;
            z-index: 2;
            padding: 10px;
            border-radius:3px;
            background-color: white;
            cursor: move;
            box-shadow: 0px 0px 12px #D1C7B8;
            color: black;
            `;

    tempPanel.innerHTML = `
        <h4 style='text-align: center; padding: 5px; margin:0; font-size: 18px;'>Legend</h4>
        <div style="max-height: 220px;">
            <table id="roomList" style="list-style-type: none; padding-left: 0; padding-top: 0;">
            </table>
        </div>
        `;

    document.body.appendChild(this.tempPanel);
    this.roomList = document.getElementById("roomList");

    this.colorPresets.forEach((color, index) => {
      let roomElem = document.createElement("tr");
      let setpointTd = document.createElement("td");
      setpointTd.style.cssText = `
            user-select:none;
            min-width: 30px;
            text-align: right;
            padding: 0px 5px;
            ;
            `;
      setpointTd.innerText = 4 - index + " °C";

      let colorTd = document.createElement("td");
      colorTd.style.cssText = `
            padding: 5px;
            width: 40px;
            text-align:center;
            border-radius: 3px;
            background-color: ${color}
            `;
      roomElem.appendChild(setpointTd);
      roomElem.appendChild(colorTd);

      // roomElem.appendChild(document.createTextNode(`[${(room.name)}] ${(room.temperature)}`));
      roomElem.onclick = event => {
        this.viewer.select(parseInt(event.target.id, 10));
      };

      function dragElement(elmnt) {
        let pos1 = 0,
          pos2 = 0,
          pos3 = 0,
          pos4 = 0;
        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = elmnt.offsetTop - pos2 + "px";
          elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
        }

        function closeDragElement() {
          /* stop moving when mouse button is released:*/
          document.onmouseup = null;
          document.onmousemove = null;
          self.isInDragMode = false;
        }

        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          self.isInDragMode = true;
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }
        if (document.getElementById("color-panel-header")) {
          /* if present, the header is where you move the DIV from:*/
          document.getElementById("color-panel-header").onmousedown = dragMouseDown;
        } else {
          /* otherwise, move the DIV from anywhere inside the DIV:*/
          elmnt.onmousedown = dragMouseDown;
        }
      }

      dragElement(tempPanel);
      this.roomList.appendChild(roomElem);
    });
  }
  createMaterial(color) {
    const material = new window.THREE.MeshPhongMaterial({
      side: window.THREE.DoubleSide,
      reflectivity: 0.0,
      flatShading: true,
      transparent: true,
      opacity: 0.7,
      color
    });

    const materials = this.viewer.impl.matman();

    materials.addMaterial("MyCustomMaterial" + color.toString(), material, true);

    return material;
  }

  // firebase
  retrieveRoomFromFb() {
    const self = this;
    const rootRef = firebase.database().ref();
    rootRef.on(
      "value",
      function(snapshot) {
        const isFirstLoad = self.rooms.length === 0;
        self.rooms = Object.keys(snapshot.val().rooms).map(key => snapshot.val().rooms[key]);
        window.dispatchEvent(new CustomEvent("newData", {}));
        if (isFirstLoad) {
          self.setupUI();
        }
      },
      function() {
        self.rooms = [];
      }
    );
  }
  normalizeRoomName(roomName) {
    if (!roomName) {
      return "";
    }
    return roomName
      .replace(/[\d\s]+/g, "")
      .trim()
      .toLowerCase();
  }
  selectSymbol(valueName) {
    switch (valueName) {
      case "customBrightness":
      case "customWarmness":
      case "effectiveFanSpeed":
        return " %";
      case "effectiveSetpoint":
      case "temperature":
        return " °C";
      default:
        return "";
    }
  }
  getRoomByName(name) {
    for (let i = 0, rLen = this.rooms.length; i < rLen; i++) {
      let n1 = this.normalizeRoomName(this.rooms[i].name);
      let n2 = this.normalizeRoomName(name);
      if (n1.contains(n2) || n2.contains(n1)) {
        return this.rooms[i];
      }
    }
    return null;
  }
  findNodeIdbyName(name) {
    let nodeList = Object.values(this.tree.nodeAccess.dbIdToIndex);
    for (let i = 1, len = nodeList.length; i < len; ++i) {
      let node_name = this.tree.getNodeName(nodeList[i]);
      if (node_name === name) {
        return nodeList[i];
      }
    }
    return null;
  }
  getFragmentWorldMatrixByNodeId(nodeId) {
    let result = {
      fragId: [],
      matrix: []
    };
    let viewer = this.viewer;
    this.tree.enumNodeFragments(nodeId, function(frag) {
      let fragProxy = viewer.impl.getFragmentProxy(viewer.model, frag);
      let matrix = new window.THREE.Matrix4();

      fragProxy.getWorldMatrix(matrix);

      result.fragId.push(frag);
      result.matrix.push(matrix);
    });
    return result;
  }

  onNewData() {
    this.updateTemperatureColor();
  }
}

window.Autodesk.Viewing.theExtensionManager.registerExtension(
  "BtibColorTempExtension",
  BtibColorTempExtension
);
