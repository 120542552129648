import firebase from "firebase/app";
import canvg from "canvg";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import HCMore from "highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
Exporting(Highcharts);
SolidGauge(Highcharts);
HCMore(Highcharts);

class BtibGauge3DExtension extends window.Autodesk.Viewing.Extension {
  constructor(viewer, options) {
    super(viewer, options);
    this.viewer = viewer;
    this.tree = null;
    this.camera = viewer.impl.camera;
    this.rooms = [];
    this.gauges = [];
    this.newDataUpdateInProgress = false;
    this.chartDiv = null;

    // binding
    this.setupUI = this.setupUI.bind(this);
    this.createGauge = this.createGauge.bind(this);
    this.getObjectTree = this.getObjectTree.bind(this);
    this.onNewData = this.onNewData.bind(this);
    this.updateRoomGaugeTexture = this.updateRoomGaugeTexture.bind(this);
    this.processCamera = this.processCamera.bind(this);
    this.createChartHiddenDiv = this.createChartHiddenDiv.bind(this);
  }

  load() {
    if (this.viewer.isExtensionLoaded("BtibFirebaseExtension")) {
      if (!this.viewer.isExtensionActive()) {
        this.viewer.activateExtension("BtibFirebaseExtension");
      }
    } else {
      return false;
    }

    this.createChartHiddenDiv();
    this.tree = this.viewer.model.getData().instanceTree;
    this.cam = this.viewer.getCamera();
    const light = new window.THREE.AmbientLight(0x404040); // soft white light
    this.viewer.impl.scene.add(light);
    window.addEventListener("newData", this.onNewData, false);
    this.retrieveRoomFromFb();
    this.viewer.addEventListener(window.Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.processCamera);
    this.viewer.disableSelection(true);
    this.viewer.disableHighlight(true);
    return true;
  }

  unload() {
    window.removeEventListener("newData", this.onNewData, false);
    if (this.chartDiv) {
      document.body.removeChild(this.chartDiv);
    }
    this.rooms.forEach(room => this.viewer.impl.scene.remove(this.gauges[room.name]));
    this.room = [];
    this.viewer.impl.invalidate(true);
    return true;
  }

  setupUI() {
    const self = this;
    const nodeId = this.findNodeIdbyName("Rooms");
    this.tree.enumNodeChildren(nodeId, child => {
      const roomName = self.tree.getNodeName(child);
      const roomData = self.getRoomByName(roomName);
      let solid = null;
      self.tree.enumNodeChildren(child, subChild => (solid ? 0 : (solid = subChild)));
      if (roomData) {
        let transMat = self.getFragmentWorldMatrixByNodeId(solid).matrix[0];
        // continue if it has transformation Matrix (meaning it is not a "group node")
        const pos = new window.THREE.Vector3();
        pos.setFromMatrixPosition(transMat);
        const chartOptions = self.createChartOptions(10);
        let mesh = self.createGauge(this.createCanvasChart(chartOptions));
        mesh.position.set(pos.x, pos.y, pos.z);
        self.viewer.impl.scene.add(mesh);
        self.gauges[roomData.name] = mesh;
      }
    });
    this.viewer.impl.invalidate(true);
  }

  getObjectTree() {
    this.viewer.removeEventListener(
      window.Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
      this.getObjectTree
    );
    this.tree = this.viewer.model.getData().instanceTree;
    this.retrieveRoomFromFb();
  }

  createChartOptions(value) {
    return {
      chart: {
        type: "gauge",
        plotBackgroundColor: "rgba(255, 255, 255, 0.0)",
        plotBackgroundImage: null,
        backgroundColor: "rgba(0,0,0,0)",
        plotBorderWidth: 0,
        plotShadow: false
      },

      title: {
        text: "Speedometer"
      },
      creadits: false,

      pane: {
        startAngle: -150,
        endAngle: 150,
        background: [
          {
            backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [[0, "#FFF"], [1, "#333"]]
            },
            borderWidth: 0,
            outerRadius: "109%"
          },
          {
            backgroundColor: {
              linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
              stops: [[0, "#333"], [1, "#FFF"]]
            },
            borderWidth: 1,
            outerRadius: "107%"
          },
          {
            // default background
          },
          {
            backgroundColor: "#DDD",
            borderWidth: 0,
            outerRadius: "105%",
            innerRadius: "103%"
          }
        ]
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 50,

        minorTickInterval: "auto",
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: "inside",
        minorTickColor: "#666",

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: "inside",
        tickLength: 10,
        tickColor: "#666",
        labels: {
          step: 2,
          rotation: "auto"
        },
        title: {
          text: "km/h"
        },
        plotBands: [
          {
            from: 0,
            to: 25,
            color: "#55BF3B" // green
          },
          {
            from: 25,
            to: 40,
            color: "#DDDF0D" // yellow
          },
          {
            from: 40,
            to: 50,
            color: "#DF5353" // red
          }
        ]
      },

      series: [
        {
          name: "Warmness",
          data: [value],
          tooltip: {
            valueSuffix: " km/h"
          }
        }
      ]
    };
  }

  createChartHiddenDiv() {
    let chartDiv = document.createElement("dive");
    chartDiv.id = "chart";
    chartDiv.style.display = "none";
    chartDiv.style.width = "500px";
    document.body.appendChild(chartDiv);
    this.chartDiv = chartDiv;
  }

  createCanvasChart(chartOptions) {
    const canvas = document.createElement("canvas");

    const chartSpeed = Highcharts.chart("chart", chartOptions);
    // Get the cart's SVG code
    const svg = chartSpeed.getSVG({
      exporting: {
        sourceWidth: chartSpeed.chartWidth,
        sourceHeight: chartSpeed.chartHeight
      }
    });

    canvas.height = chartSpeed.chartWidth;
    canvas.width = chartSpeed.chartHeight;

    canvg(canvas, svg);
    document.getElementById("chart").appendChild(canvas);
    return canvas;
  }
  createGauge(canvas) {
    let texture = new window.THREE.Texture(canvas);
    texture.minFilter = window.THREE.NearestFilter;
    let material = new window.THREE.MeshBasicMaterial({
      map: texture,
      transparent: true,
      alphaTest: 0.5,
      side: window.THREE.DoubleSide
    });
    let geometry = new window.THREE.PlaneBufferGeometry(10, 7);
    let mesh = new window.THREE.Mesh(geometry, material);
    mesh.rotation.x = Math.PI / 2;
    mesh.rotation.y = Math.PI / 2;
    texture.needsUpdate = true;
    return mesh;
  }

  updateRoomGaugeTexture(room) {
    const mesh = this.gauges[room.name];
    if (!mesh) {
      return;
    }
    const chartOptions = this.createChartOptions(room.hvac.effectiveSetpoint || -1);
    const canvas = this.createCanvasChart(chartOptions);
    const texture = new window.THREE.Texture(canvas);
    texture.minFilter = window.THREE.NearestFilter;
    mesh.material.map = texture;
    texture.needsUpdate = true;
  }
  // event
  onNewData(e) {
    this.newDataUpdateInProgress = true;
    this.rooms.forEach(room => {
      if (room.needsUpdate) {
        this.updateRoomGaugeTexture(room);
      }
    });
    this.viewer.impl.invalidate(true);
  }

  // firebase
  retrieveRoomFromFb() {
    const self = this;
    const rootRef = firebase.database().ref("rooms");
    rootRef.on(
      "value",
      function(snapshot) {
        const isFirstLoad = self.rooms.length === 0;
        self.rooms = Object.keys(snapshot.val()).map(key => {
          let room = snapshot.val()[key];
          let oldRoom = self.rooms.find(r => r.name.contains(room.name));
          if (oldRoom && oldRoom.hvac.effectiveSetpoint !== room.hvac.effectiveSetpoint) {
            room.needsUpdate = true;
          } else {
            room.needsUpdate = false;
          }
          return room;
        });
        if (isFirstLoad) {
          self.setupUI();
        }
        window.dispatchEvent(new CustomEvent("newData", {}));
      },
      function(error) {
        self.rooms = [];
      }
    );
  }
  normalizeRoomName(roomName) {
    if (!roomName) {
      return "";
    }
    return roomName
      .replace(/[\d\s]+/g, "")
      .trim()
      .toLowerCase();
  }
  selectSymbol(valueName) {
    switch (valueName) {
      case "customBrightness":
      case "customWarmness":
      case "effectiveFanSpeed":
        return " %";
      case "effectiveSetpoint":
      case "temperature":
        return " °C";
      default:
        return "";
    }
  }
  getRoomByName(name) {
    for (let i = 0, rLen = this.rooms.length; i < rLen; i++) {
      let n1 = this.normalizeRoomName(this.rooms[i].name);
      let n2 = this.normalizeRoomName(name);
      if (n1.contains(n2) || n2.contains(n1)) {
        return this.rooms[i];
      }
    }
    return null;
  }
  findNodeIdbyName(name) {
    let nodeList = Object.values(this.tree.nodeAccess.dbIdToIndex);
    for (let i = 1, len = nodeList.length; i < len; ++i) {
      let node_name = this.tree.getNodeName(nodeList[i]);
      if (node_name === name) {
        return nodeList[i];
      }
    }
    return null;
  }
  getFragmentWorldMatrixByNodeId(nodeId) {
    let result = {
      fragId: [],
      matrix: []
    };
    let viewer = this.viewer;
    this.tree.enumNodeFragments(nodeId, function(frag) {
      let fragProxy = viewer.impl.getFragmentProxy(viewer.model, frag);
      let matrix = new window.THREE.Matrix4();

      fragProxy.getWorldMatrix(matrix);

      result.fragId.push(frag);
      result.matrix.push(matrix);
    });
    return result;
  }
  processCamera() {
    Object.keys(this.gauges).forEach(roomName => {
      this.gauges[roomName].quaternion.copy(this.camera.quaternion);
    });
    this.viewer.impl.invalidate(true);
  }
}

window.Autodesk.Viewing.theExtensionManager.registerExtension(
  "BtibGauge3DExtension",
  BtibGauge3DExtension
);
